<script>
import Vue from "vue";
import Layout from "@/router/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import Switches from "vue-switches";
import Preloader from "@/components/preloader";
import { email, minLength, required } from "vuelidate/lib/validators";
import { typeOfRoles } from "@/data/data-type-of-roles";
import Repository from "@/app/repository/repository-factory";
import { roleService } from "@/app/service/roleService";

const UserAccountRepository = Repository.get("UserAccountRepository");
const CoreCompanyRepository = Repository.get("CoreCompanyRepository");

/**
 * Add user account
 */
export default {
  page: {
    title: "Edycja użytkownika",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Preloader, Multiselect, Switches },
  data() {
    return {
      disabledBtn: false,
      preloader: false,
      header: {
        title: "Edycja użytkownika",
        items: [
          {
            text: "Strona główna",
            to: { name: "home" },
          },
          {
            text: "Lista użytkowników",
            to: { name: "Lista użytkowników" },
          },
          {
            text: "Edycja użytkownika",
          },
        ],
      },
      form: {
        firstName: null,
        lastName: null,
        email: null,
        position: null,
        isActive: true,
        roles: [],
        typeOfUserAccount: null,
        externalId: null,
        permissions: [],
      },
      roles: {
        acceptorPermission: [],
        acceptorCardPermission: [],
        acceptorKRSPermission: [],
        applicantPermission: [],
      },
      rolesSelectOptions: typeOfRoles,
      typeOfUserAccountSelectOptions: [
        { value: 1, text: "Konto lokalne" },
        { value: 2, text: "Konto Active Directory" },
      ],
      companySelectOptions: [],
      submitted: false,
    };
  },
  validations: {
    form: {
      firstName: { required, minLength: minLength(3) },
      lastName: { required, minLength: minLength(3) },
      email: { required, email },
      position: { minLength: minLength(3) },
      roles: { required },
      typeOfUserAccount: { required },
      externalId: {},
    },
  },
  created() {
    this.getCompanies();
    this.getUserAccount();
  },
  methods: {
    getCompanies() {
      CoreCompanyRepository.getAllActive()
        .then((response) => {
          let companies = [];
          let i;

          for (i = 0; i < response.data.length; i++) {
            companies.push({
              text: response.data[i].name,
              value: response.data[i].companyId,
            });
          }

          this.companySelectOptions = companies;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    mappingPermissions(permissions) {
      if (permissions.length === 0) {
        return;
      }

      let i;
      this.roles.applicantPermission = [];
      this.roles.acceptorPermission = [];
      this.roles.acceptorCardPermission = [];
      this.roles.acceptorKRSPermission = [];
      for (i = 0; i < permissions.length; i++) {

        if (permissions[i].userAccountPermissionType === 1) {
          this.roles.applicantPermission.push({
            text: permissions[i].companyName,
            value: permissions[i].companyId,
          });
        }

        if (permissions[i].userAccountPermissionType === 2) {
          this.roles.acceptorPermission.push({
            text: permissions[i].companyName,
            value: permissions[i].companyId,
          });
        }

        if (permissions[i].userAccountPermissionType === 4) {
          this.roles.acceptorCardPermission.push({
            text: permissions[i].companyName,
            value: permissions[i].companyId,
          });
        }

        if (permissions[i].userAccountPermissionType === 3) {
          this.roles.acceptorKRSPermission.push({
            text: permissions[i].companyName,
            value: permissions[i].companyId,
          });
        }

      }
    },
    async getUserAccount() {
      this.preloader = true;
      if (this.$route.params.id) {
        await UserAccountRepository.get(this.$route.params.id)
          .then((response) => {
            this.form = response.data;
            let roles = [];
            let i;

            for (i = 0; i < this.rolesSelectOptions.length; i++) {
              if (
                response.data.roles.includes(this.rolesSelectOptions[i].value)
              ) {
                roles.push(this.rolesSelectOptions[i]);
              }
            }

            this.form.roles = roles;
            this.form.typeOfUserAccount = this.typeOfUserAccountSelectOptions.find(
              (o) => o.value === response.data.typeOfUserAccount
            );
            this.mappingPermissions(response.data.permissions);
          })
          .catch((error) => {
            console.log(error);
          });
        this.preloader = false;
      }
    },
    updateUserAccount() {
      this.formSubmit();

      if (this.$v.$error === true) {
        return false;
      }

      let i;
      let roles = [];
      let applicantRole, acceptorRole, acceptorCardRole, acceptorKRSRole = false;
      for (i = 0; i < this.form.roles.length; i++) {
        if (this.form.roles[i].value === "ROLE_APPLICANT") {
          applicantRole = true;
        }

        if (this.form.roles[i].value === "ROLE_ACCEPTING") {
          acceptorRole = true;
        }

        if (this.form.roles[i].value === "ROLE_ACCEPTING_CARD") {
          acceptorCardRole = true;
        }

        if (this.form.roles[i].value === "ROLE_ACCEPTING_KRS") {
          acceptorKRSRole = true;
        }

        roles.push(this.form.roles[i].value);
      }

      // Mapping permission to backend
      this.form.permissions = [];
      if (applicantRole) {
        for (i = 0; i < this.roles.applicantPermission.length; i++) {
          this.form.permissions.push({
            userAccountPermissionType: 1,
            companyId: this.roles.applicantPermission[i].value,
          });
        }
      }

      if (acceptorRole) {
        for (i = 0; i < this.roles.acceptorPermission.length; i++) {
          this.form.permissions.push({
            userAccountPermissionType: 2,
            companyId: this.roles.acceptorPermission[i].value,
          });
        }
      }

      if (acceptorCardRole) {
        for (i = 0; i < this.roles.acceptorCardPermission.length; i++) {
          this.form.permissions.push({
            userAccountPermissionType: 4,
            companyId: this.roles.acceptorCardPermission[i].value,
          });
        }
      }

      if (acceptorKRSRole) {
        for (i = 0; i < this.roles.acceptorKRSPermission.length; i++) {
          this.form.permissions.push({
            userAccountPermissionType: 3,
            companyId: this.roles.acceptorKRSPermission[i].value,
          });
        }
      }

      this.disabledBtn = true;
      this.preloader = true;

      let payload = Object.assign({}, this.form);
      payload.roles = roles;
      payload.typeOfUserAccount = this.form.typeOfUserAccount.value;

      UserAccountRepository.update(payload, this.$route.params.id)
        .then(() => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "success",
            toast: false,
            position: "top",
            title: "SUKCES!",
            text: "Użytkownik został zaktualizowany!",
            showConfirmButton: false,
            timer: 3500,
          });
          this.getUserAccount();
        })
        .catch((error) => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "error",
            position: "top",
            title: "Wystąpił błąd. Spróbuj jeszcze raz!",
            text: error.response.data.message,
            showConfirmButton: true,
          });
        });
    },
    formSubmit(e) {
      this.submitted = true;
      this.$v.$touch();
    },
    checkApplicantPermissionExist() {
      let r = [];
      this.roles.acceptorPermission.forEach((role) => {
        r = this.roles.applicantPermission.filter((obj) => {
          return obj.value === role.value;
        });
      });
      return r;
    },
    checkAcceptorPermissionExist() {
      let r = [];
      this.roles.applicantPermission.forEach((role) => {
        r = this.roles.acceptorPermission.filter((obj) => {
          return obj.value === role.value;
        });
      });
      return r;
    },
    checkAcceptorCardPermissionExist() {
      let r = [];
      this.roles.applicantPermission.forEach((role) => {
        r = this.roles.acceptorCardPermission.filter((obj) => {
          return obj.value === role.value;
        });
      });
      return r;
    },
    checkAcceptorKRSPermissionExist() {
      let r = [];
      this.roles.applicantPermission.forEach((role) => {
        r = this.roles.acceptorKRSPermission.filter((obj) => {
          return obj.value === role.value;
        });
      });
      return r;
    },
    checkAndClearIfAccOrApp({ value }) {
      if (value == "ROLE_APPLICANT") {
        this.roles.applicantPermission = [];
      }
      if (value == "ROLE_ACCEPTING") {
        this.roles.acceptorPermission = [];
      }
      if (value == "ROLE_ACCEPTING_CARD") {
        this.roles.acceptorCardPermission = [];
      }
      if (value == "ROLE_ACCEPTING_KRS") {
        this.roles.acceptorKRSPermission = [];
      }
    },
  },
  computed: {
    isAdmin() {
      return roleService.isAdmin();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="header.title" :items="header.items" />
    <Preloader v-if="preloader" />
    <b-form @submit.prevent="formSubmit" v-if="isAdmin">
      <div class="row">
        <div class="col-lg-7">
          <div class="card shadow-sm">
            <div class="card-body">
              <h4 class="card-title">
                Edycja użytkownika
              </h4>
              <p class="card-title-desc">
                Tutaj możesz edytować użytkownika.
              </p>
              <b-alert variant="info" class="mb-3" show fade>
                <i class="mdi mdi-information-outline mr-2"></i><strong>Uwaga!</strong> Możliwość logowania do panelu administratora posiadają tylko konta Active Directory.
              </b-alert>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group required">
                    <label for="firstName">Imię:</label>
                    <input id="firstName" v-model="form.firstName" type="text" class="form-control" placeholder="Imię" value="" :class="{ 'is-invalid': submitted && $v.form.firstName.$error }">
                    <div v-if="submitted && $v.form.firstName.$error" class="invalid-feedback">
                      <span v-if="!$v.form.firstName.required">Ta wartość jest wymagana.<br></span>
                      <span v-if="!$v.form.firstName.minLength">Ta wartość musi być dłuższa niż 2 znaki.<br></span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group required">
                    <label for="firstName">Nazwisko:</label>
                    <input id="lastName" v-model="form.lastName" type="text" class="form-control" placeholder="Nazwisko" value="" :class="{ 'is-invalid': submitted && $v.form.lastName.$error }">
                    <div v-if="submitted && $v.form.lastName.$error" class="invalid-feedback">
                      <span v-if="!$v.form.lastName.required">Ta wartość jest wymagana.<br></span>
                      <span v-if="!$v.form.lastName.minLength">Ta wartość musi być dłuższa niż 2 znaki.<br></span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group required">
                    <label for="firstName">E-mail:</label>
                    <input id="email" v-model="form.email" type="text" class="form-control" placeholder="Email" value="" :class="{ 'is-invalid': submitted && $v.form.email.$error }">
                    <div v-if="submitted && $v.form.email.$error" class="invalid-feedback">
                      <span v-if="!$v.form.email.required">Ta wartość jest wymagana.<br></span>
                      <span v-if="!$v.form.email.email">Ta wartość musi być poprawnym adresem e-mail.<br></span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="firstName">Stanowisko:</label>
                    <input id="position" v-model="form.position" type="text" class="form-control" placeholder="Stanowisko" value="" :class="{ 'is-invalid': submitted && $v.form.position.$error }">
                    <div v-if="submitted && $v.form.position.$error" class="invalid-feedback">
                      <span v-if="!$v.form.position.required">Ta wartość jest wymagana.<br></span>
                      <span v-if="!$v.form.position.minLength">Ta wartość musi być dłuższa niż 2 znaki.<br></span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group required">
                    <label for="firstName">Typ konta:</label>
                    <multiselect v-model="form.typeOfUserAccount" :class="{ 'is-invalid': submitted && $v.form.typeOfUserAccount.$error }" :multiple="false" :allow-empty="false" :options="typeOfUserAccountSelectOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" />

                    <div v-if="submitted && $v.form.typeOfUserAccount.$error" class="invalid-feedback">
                      <span v-if="!$v.form.typeOfUserAccount.required">Ta wartość jest wymagana.<br></span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div v-if="form.typeOfUserAccount !== null && form.typeOfUserAccount.value !== 1" class="form-group required">
                    <label for="firstName">Zewnętrzny identyfikator konta:</label>
                    <input id="externalId" v-model="form.externalId" type="text" class="form-control" placeholder="Zewnętrzny identyfikator użytkownika">
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <div class="d-flex justify-content-start">
                      <switches class="mt-2" v-model="form.isActive" type-bold="false" :color="form.isActive == 1 ? 'success' : 'primary'"></switches>
                      <label class="mt-1 ml-3"><span class="mr-2">Aktywny:</span>
                        <span v-if="form.isActive" class="badge badge-pill badge-soft-success font-size-14">TAK</span>
                        <span v-else class="badge badge-pill badge-soft-danger font-size-14">NIE</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-dark" :disabled="this.$v.$error || this.$v.$invalid || disabledBtn || checkApplicantPermissionExist().length > 0 || checkAcceptorPermissionExist().length > 0 || checkAcceptorCardPermissionExist().length > 0 || checkAcceptorKRSPermissionExist().length > 0" type="submit" @click="updateUserAccount">
                Aktualizuj użytkownika
              </button>
            </div>
          </div>
          <div class="col-lg-4" />
        </div>

        <div class="col-lg-5">
          <div class="card shadow-sm">
            <div class="card-body">
              <h4 class="card-title">
                Zarządzanie uprawnieniami
              </h4>
              <p class="card-title-desc">
                Wybierz rolę i przypisz do niej uprawnienia.
              </p>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group required">
                    <label for="firstName">Rola:</label>
                    <multiselect @remove="checkAndClearIfAccOrApp" v-model="form.roles" :class="{ 'is-invalid': submitted && $v.form.roles.$error }" :multiple="true" :options="rolesSelectOptions" :options-limit="1000" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓">
                      <template v-slot:noResult>
                        Brak rezultatów.
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.text }}</span>
                          <br />
                          <span class="option__small small text-dark">{{ props.option.app }}</span>
                        </div>
                      </template>
                      <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>
                    <div v-if="submitted && $v.form.roles.$error" class="invalid-feedback">
                      <span v-if="!$v.form.roles.required">Ta wartość jest wymagana.<br></span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div v-if="form.roles.length > 0 && form.roles.find(o => o.value === 'ROLE_APPLICANT')" class="form-group">
                    <label for="firstName">Uprawnienia wnioskującego:</label>
                    <multiselect v-model="roles.applicantPermission" :multiple="true" :options="companySelectOptions" :preserve-search="true" @input="checkApplicantPermissionExist" :class="{ 'is-invalid': checkApplicantPermissionExist().length > 0 }" track-by="value" label="text" placeholder="Wybierz" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" >
                      <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>
                  </div>
                  <span v-if="checkApplicantPermissionExist().length > 0" class="d-block small text-danger p-0 mt-n2">Użytkownik nie może być równocześnie wnioskującym i akceptującym w tej samej spółce!</span>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div v-if="form.roles.length > 0 && (form.roles.find(o => o.value === 'ROLE_ACCEPTING') || form.roles.find(o => o.value === 'ROLE_SUPER_ACCEPTING'))" class="form-group">
                    <label for="firstName">Uprawnienia akceptującego przelewy:</label>
                    <multiselect v-model="roles.acceptorPermission" :multiple="true" :options="companySelectOptions" :preserve-search="true" @input="checkAcceptorPermissionExist" :class="{ 'is-invalid': checkAcceptorPermissionExist().length > 0 }" track-by="value" label="text" placeholder="Wybierz" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" >
                      <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>
                  </div>
                  <span v-if="checkAcceptorPermissionExist().length > 0" class="d-block small text-danger p-0 mt-n2">Użytkownik nie może być równocześnie akceptującym i wnioskującym w tej samej spółce!</span>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div v-if="form.roles.length > 0 && (form.roles.find(o => o.value === 'ROLE_ACCEPTING_CARD'))" class="form-group">
                    <label for="firstName">Uprawnienia akceptującego zasilenie kart:</label>
                    <multiselect v-model="roles.acceptorCardPermission" :multiple="true" :options="companySelectOptions" :preserve-search="true" @input="checkAcceptorCardPermissionExist" :class="{ 'is-invalid': checkAcceptorCardPermissionExist().length > 0 }" track-by="value" label="text" placeholder="Wybierz" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" >
                      <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>
                  </div>
                  <span v-if="checkAcceptorCardPermissionExist().length > 0" class="d-block small text-danger p-0 mt-n2">Użytkownik nie może być równocześnie akceptującym i wnioskującym w tej samej spółce!</span>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div v-if="form.roles.length > 0 && form.roles.find(o => o.value === 'ROLE_ACCEPTING_KRS')" class="form-group">
                    <label for="firstName">Uprawnienia akceptującego karty nierozliczone:</label>
                    <multiselect v-model="roles.acceptorKRSPermission" :multiple="true" :options="companySelectOptions" :preserve-search="true" @input="checkAcceptorKRSPermissionExist" :class="{ 'is-invalid': checkAcceptorKRSPermissionExist().length > 0 }" track-by="value" label="text" placeholder="Wybierz" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" >
                      <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>
                  </div>
                  <span v-if="checkAcceptorKRSPermissionExist().length > 0" class="d-block small text-danger p-0 mt-n2">Użytkownik nie może być równocześnie akceptującym KRS i wnioskującym w tej samej spółce!</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </Layout>
</template>
